import classes from "./ReportsPanel.module.scss";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver";
import { parseMinutesToHours } from "../helpers/time";
import { db } from "@vercel/postgres";
import { useSession } from "next-auth/react";

export default function ReportsPanel(props) {
  let dateFrom = props.dateFrom;
  let dateTo = props.dateTo;
  const { data: session, status } = useSession();

  const formatMonthName = (date) => {
    return date.toLocaleString("en-US", { month: "long" });
  };

  const formatMonthAndYear = (date) => {
    return date.toLocaleString("en-US", { month: "long" }) + "_" + date.getFullYear();
  };

  const formatYear = (date) => {
    return date.getFullYear();
  };

  const formatExcelDate = (date) => {
    const dateObj = new Date(date);
    return `${("0" + dateObj.getDate()).slice(-2)}/${(
      "0" +
      dateObj.getMonth() +
      1
    ).slice(-2)}/${dateObj.getFullYear()}`;
  };

  const getReport = async (date, period = 'monthly') => {
    try {
      const entriesReq = await fetch(`/api/hdd/report/get-${period}/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          date: date,
          user: session.user,
        }),
      });

      const entriesData = await entriesReq.json();

      await saveReport(entriesData, date, period);
    } catch (error) {
      console.error("Unable to get data", error);
      alert(error.message);
    }
  };

  const getDivisionFromBrand = (brand) => {
    const brandsToDivisionMap = {
      "CMT": "CMT",
      "ZP": "CMT",
      "VLM": "CMT",
      "Retail Media Ninja": "CMT",
      "NP": "CMT",
      "Shopnomix": "CMT",
      "Adsolutely": "SEARCH",
      "ParkingCrew": "SEARCH",
      "Tonic": "SEARCH",
      "TI_DE": "SEARCH",
      "BrandShelter": "ONLINE",
      "SafeBrands": "ONLINE",
      "nicmanager": "ONLINE",
      "DomiNic": "ONLINE",
      "GlobalBlock": "ONLINE",
      "Cnic_Registry": "ONLINE",
      "CNIC_Reseller": "ONLINE",
      "Hexonet": "ONLINE",
      "TPP_Wholesale": "ONLINE",
      "Key-Systems": "ONLINE",
      "OnlyDomains": "ONLINE",
      "Domaindiscount24": "ONLINE",
      "Moniker": "ONLINE",
      "Instra": "ONLINE",
      "Internetbs": "ONLINE",
      "iwantmyname": "ONLINE",
      "GlobeHosting": "ONLINE",
      "Team Internet_HR/People": "TI_HQ",
      "Team Internet_CEO Office": "TI_HQ",
    };

    return brandsToDivisionMap[brand] ?? 'N/A';
  };

  const saveReport = async (data, date, period) => {
    let fileName = `Marketing_Shared_Service_Report_${period === 'monthly' ? `${formatMonthName(date)}_` : ''}${formatYear(date)}_All_Hours`;

    const workbook = new Workbook();

    // get all unique assigneess from data 
    const assignees = data.reduce((cur, next) => {
      if (!cur.includes(next.assignee_name)) {
        cur.push(next.assignee_name);
      }
      return cur;
    }, []);

    const insertData = (person) => {
      const worksheet = workbook.getWorksheet(person);
      let filteredData;

      // const worksheet = workbook.addWorksheet(`${fileName}`);

      if (data.length === 0) {
        throw Error("No time logged in this month");
      } else {
        if (person === "DEV") {
          filteredData = data.filter((row) =>
            row.assignee_team === "dev"
          );
        } else if (person === "DESIGN") {
          filteredData = data.filter((row) =>
            row.assignee_team === "design"
          );
        } else if (person === "PM_CONTENT") {
          filteredData = data.filter((row) =>
            row.assignee_team === "pm_content"
          );
        } else if (person === "EVENTS") {
          filteredData = data.filter((row) =>
            row.assignee_team === "events"
          );
        } else if (person === "DESIGN_DEV") {
          filteredData = data.filter((row) =>
            row.assignee_team === "design" || row.assignee_team === 'dev'
          );
        } else if (person === "ALL") {
          filteredData = data;
        } else {
          filteredData = data.filter((row) => row.assignee_name === person);
        }
      }

      const header = worksheet.addRow([
        "Date",
        "Division",
        "Brand",
        "Type",
        "Task",
        "Task external URL",
        "Assignee Name",
        "Assignee Email",
        "Assignee Employee ID",
        "Hours",
      ]);

      header.font = {
        bold: true,
      };

      for (const dbDataRow of filteredData) {
        worksheet.addRow([
          new Date(dbDataRow.entry_date),
          getDivisionFromBrand(dbDataRow.entry_brand),
          dbDataRow.entry_brand,
          dbDataRow.entry_type,
          dbDataRow.entry_title +
            (dbDataRow.entry_description
              ? ` (${dbDataRow.entry_description})`
              : ""),
          dbDataRow.entry_url,
          dbDataRow.assignee_name,
          dbDataRow.assignee_email,
          dbDataRow.assignee_employee_id,
          parseFloat(parseMinutesToHours(dbDataRow.entry_duration)),
        ]);
      }

      worksheet.columns.forEach((column) => {
        const lengths = column.values.map(
          (v) => (v instanceof Date ? 10 : v.toString().length) + 2
        );
        const maxLength = Math.max(
          ...lengths.filter((v) => typeof v === "number")
        );
        column.width = maxLength;
      });
    };

    if (data.length !== 0) {
      assignees.forEach((name) => {
        workbook.addWorksheet(name);
        insertData(name);
      });

      if (session.user.role === "DEV_LEAD" || session.user.role === "BOSS") {
        workbook.addWorksheet("DEV");
        insertData("DEV");
      }

      if (session.user.role === "DESIGN_LEAD" || session.user.role === "BOSS") {
        workbook.addWorksheet("DESIGN");
        insertData("DESIGN");
      }

      if (session.user.role === "PM_CONTENT_LEAD" || session.user.role === "BOSS") {
        workbook.addWorksheet("PM_CONTENT");
        insertData("PM_CONTENT");
      }

      if (session.user.role === "BOSS") {
        workbook.addWorksheet("DESIGN_DEV");
        insertData("DESIGN_DEV");
        workbook.addWorksheet("ALL");
        insertData("ALL");
        fileName += "_All";
      }

      if(session.user.role === "USER") {
        fileName += `_${session.user.name.split(' ').join('_')}`;
      }

      if(session.user.role === "PM_CONTENT_LEAD") {
        fileName += `_PM_Content`;
      }

      if(session.user.role === "EVENTS_LEAD") {
        fileName += `_Events`;
      }

      if(session.user.role === "DEV_LEAD") {
        fileName += `_Dev`;
      }

      if(session.user.role === "DESIGN_LEAD") {
        fileName += `_Design`;
      }

      workbook.xlsx.writeBuffer().then((data) => {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
        });
        saveAs(blob, `${fileName}.xlsx`);
      });
    } else {
      throw Error("No time logged in this month");
    }
  };

  return (
    <div className={classes.reportsPanel}>
      <span>Generate report for: </span>
        <div className={classes.multipleBtns}>
          <button
            className={classes.generateBtn}
            onClick={() => getReport(dateFrom)}
          >
            {formatMonthName(dateFrom)}
          </button>
          {dateFrom.getMonth() !== dateTo.getMonth() ? (
            <button
              className={classes.generateBtn}
              onClick={() => getReport(dateTo)}
            >
              {formatMonthName(dateTo)}
            </button>
          ) : () => {}}
          <button
            className={classes.generateBtn}
            onClick={() => getReport(dateFrom, 'yearly')}
          >
            {formatYear(dateFrom)}
          </button>
        </div>
    </div>
  );
}
