import { useEffect, useState } from "react";
import classes from "../DingDongPageComponent.module.scss";
import { useSession } from "next-auth/react";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../../store/hdd/hdd-store";

export default function UserSelect(props) {
  const { data: session } = useSession();
  const dispatch = useDispatch();
  const storeUser = useSelector((state) => state.hdd.user);
  const [users, setUsers] = useState(null);

  // const BOSS_TEAM = [
  //   "jola.ksiazek@cm.tech"
  // ];

  const getUsers = async () => {
    const usersReq = await fetch("/api/hdd/users/get");
    const usersData = await usersReq.json();
    const { rows } = usersData;
    setUsers(rows);
  };

  useEffect(() => {
    if (session && session.user.role !== "USER") {
      getUsers();
    }
  }, [session]);

  return (
    <>
      {users && (
        <select
          className={classes.userSelect}
          name="userId"
          id=""
          value={storeUser.id ? storeUser.id : ""}
          onChange={(e) => dispatch(setUser({ id: e.target.value }))}
        >
          {users &&
            users
              .filter((user) => {
                if (
                  session.user.role === "DEV_LEAD" &&
                  user.id !== session.user.id // skip current user
                ) {
                  return user.team === "dev";
                }

                if (
                  session.user.role === "DESIGN_LEAD" &&
                  user.id !== session.user.id // skip current user
                ) {
                  return user.team === "design";
                }

                if (
                  session.user.role === "PM_CONTENT_LEAD" &&
                  user.id !== session.user.id // skip current user
                ) {
                  return user.team === "pm_content";
                }

                if (
                  session.user.role === "EVENTS_LEAD" &&
                  user.id !== session.user.id // skip current user
                ) {
                  return user.team === "events";
                }

                return user;
              })
              .map((user) => (
                <option key={user.name} value={user.id}>
                  {user.name} {session.user.id === user.id ? "(you)" : ""}
                </option>
              ))}
        </select>
      )}
    </>
  );
}