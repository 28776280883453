import classes from "./WeekTable.module.scss";
import SingleEntry from "./SingleEntry";
import cn from "classnames";
import { parseMinutesToTime, isSameDay } from "../helpers/time";
import { useDispatch } from "react-redux";
import { setPopupState, setAddingDate } from "../../../store/hdd/hdd-store";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";

export default function WeekTable(props) {
  const today = new Date();
  const onSingleEntryChange = () => {
    props.fetchEntries();
  };

  const dispatch = useDispatch();

  const showDayName = (dateToDisplay) => {
    if (dateToDisplay instanceof Date && !isNaN(dateToDisplay)) {
      const formattedDate = dateToDisplay.toLocaleDateString("en-US", {
        weekday: "long",
        day: "numeric",
        month: "long",
      });
      const [dayName, month, dayNumber] = formattedDate.split(" ");
      const cleanedDayName = dayName.replace(",", "");

      return cleanedDayName;
    } else {
      return null;
    }
  };

  const showDay = (date) => {
    if (date instanceof Date && !isNaN(date)) {
      return `${("0" + date.getDate()).slice(-2)}.${("0" + (date.getMonth() + 1)).slice(-2)}`;
    } else {
      return null;
    }
  };

  const sumDayDuration = (entries, toTime = false) => {
    let sum = 0;

    if (entries && entries.length) {
      sum = entries.reduce((acc, item) => acc + item.entry_duration, 0);
    }

    if (toTime) {
      sum = parseMinutesToTime(sum);
    }

    return sum;
  };

  const onClickAdd = (date) => {
    dispatch(setPopupState("add"));
    dispatch(setAddingDate(date));
  }

  // =====================================

  const reorder = (list, startIndex, endIndex) => {
    console.log(list, startIndex, endIndex);
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    // Create a new array with updated entry_order for each item
    const updatedResult = result.map((item, index) => ({
      ...item,
      entry_order: index,
    }));

    return updatedResult;
  };

  const move = (source, destination, droppableSource, droppableDestination, destinationDate) => {
    const sourceClone = source.map(item => ({ ...item }));
    const destClone = destination.map(item => ({ ...item }));
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    // Update entry_order for each item in the array
    sourceClone.forEach((item, index) => {
      item.entry_order = index;
    });
    
    // Update entry_order and entry_date for each item in the array
    destClone.forEach((item, index) => {
      item.entry_order = index;
      item.entry_date = destinationDate;
    });

    const result = {};
    result["source"] = sourceClone;
    result["destination"] = destClone;

    return result;
  };

  const getEntriesByDate = (data, targetDate) => {
    // Convert targetDate to a comparable format
    const formattedTargetDate = new Date(targetDate).toISOString().split('T')[0];
    
    // Find the matching day
    const matchingDay = data.days.find(day => {
        const formattedDayDate = new Date(day.date).toISOString().split('T')[0];
        return formattedDayDate === formattedTargetDate;
    });

    // Return the entries or an empty array if no matching day is found
    return matchingDay ? matchingDay.entries : [];
  }

  const onDragEnd = (result) => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    const destinationDate = destination?.droppableId.replace("droppableDay-", "") ?? "";
    const sourceDate = source?.droppableId.replace("droppableDay-", "") ?? "";
    const sourceEntries = getEntriesByDate(props.entries, sourceDate);
    const destinationEntries = getEntriesByDate(props.entries, destinationDate);

    const sInd = source.droppableId;
    const dInd = destination.droppableId;
    const isSameDay = sInd === dInd;
    let entriesToReorder = [];

    if (isSameDay) {
      const reorderedItems = reorder(sourceEntries, source.index, destination.index);
      entriesToReorder = [...reorderedItems]
    } else {
      const reorderedItems = move(sourceEntries, destinationEntries, source, destination, destinationDate);
      const { source: sourceItems, destination: destinationItems } = reorderedItems;
      entriesToReorder = [...sourceItems, ...destinationItems]
    }

    props.reorderEntries(entriesToReorder);
  }
  
  return (
    <div className={classes.weekTable}>
      <DragDropContext onDragEnd={onDragEnd}>
        <div className={classes.weekTableBody}>
          {props.entries.days.map((item, indexDays) => (
            <div
            className={cn(classes.weekTableBodyCol, {
              [classes.todayStyle]: isSameDay(item.date, today),
            })}
            >
              <div key={item.date} className={cn(classes.weekTableHead)}>
                <div className={classes.dayName}>{showDayName(new Date(item.date))}</div>
                <div className={cn(classes.weekTableHeadAddWrapper)}>
                  <button className={classes.addBtn} onClick={() => { onClickAdd(item.date) }}>
                    <div>+ Add <span>entry</span></div>
                  </button>
                </div>
                <div className={classes.dayDate}>{showDay(new Date(item.date))}</div>
                <div className={classes.totalTimeWrapper}>
                  <span className={classes.totalTime}><span>Total:</span> {sumDayDuration(item.entries, true)}</span>
                </div>
              </div>
              <div className={cn(classes.weekTableContent)} data-day-content>
                <Droppable key={`droppableDay-${item.date}`} droppableId={`droppableDay-${item.date}`}>
                  
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    className={cn(
                      classes.weekTableContentDroppable, 
                      snapshot.isDraggingOver ? classes.weekTableContentDroppableOver : "")
                    }
                    {...provided.droppableProps}
                  >
                    {item.entries.map((item, index) => (
                      <Draggable
                        key={`draggableId-${item.id}-${index}`}
                        draggableId={`draggableId-${item.id}`}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={cn(
                              classes.weekTableContentDraggable, 
                              snapshot.isDragging ? classes.weekTableContentDraggableDragging : "")
                            }
                          >
                            <SingleEntry
                              entry={item}
                              onSingleEntryChange={onSingleEntryChange}
                              key={item.id+index}
                              index={index}
                            />
                          </div>
                        )}
                        </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
                </Droppable>
              </div>
            </div>
          ))}
        </div>
      </DragDropContext>
    </div>
  );
}
